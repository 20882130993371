import {TParticipants} from "../../models/types/Participants";
import {createSelector} from "reselect";
import {TEnoField} from "../../models/entities/information/enoField";
import {ADDRESS, CITY, EMAIL, NAME, PHONENUMBER, POSTCODE} from "../../../config/app-config";
import {EParticipant} from "../../models/entities/participant/participant";
import {t} from "i18next";
import { selectSelectedOperation } from '../operation/operationSelector';


const participants = (state: { participants: TParticipants }) => state.participants;

const selectUserParticipants = createSelector(
    participants,
    participants => participants && participants.participants
)
const selectCurrentParticipant = createSelector(
    participants,
    participants => participants && participants.currentParticipant
)

const selectCurrentParticipantId = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._id
)
const selectCurrentParticipantOperationId = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._operationId
)
const selectCurrentParticipantUserId = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._userId
)

// My-Account
const  selectCurrentParticipantEnedisDetail = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._enedisDetail
)
const  selectCurrentParticipantDsoDetails = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._dsoDetails
)
const selectCurrentParticipantBillingDetail = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._billingDetail
)
const selectParticipantInfos = createSelector(
    selectCurrentParticipant,
    selectSelectedOperation,
    (currentParticipant, operation) => {
        const participantInfos: TEnoField[][] = []
        participantInfos.push([{_type: t(`words.${operation.dso}.dsoIdentifierNumber`), _value: currentParticipant._dsoDetails._dsoIdentifier || "", _editable: false}])
        participantInfos.push([{_type: ADDRESS(t), _value: currentParticipant._dsoDetails._address|| "", _editable: true}])
        participantInfos.push([{_type: CITY(t), _value: currentParticipant._dsoDetails._city|| "", _editable: true}])
        participantInfos.push([{_type: POSTCODE(t), _value: currentParticipant._dsoDetails._postCode|| "", _editable: true}])
        participantInfos.push([{_type: NAME(t), _value: currentParticipant._dsoDetails._name|| "", _editable: true}])
        return participantInfos || []
    }
)
const selectBillingInfos = createSelector(
    selectCurrentParticipant,
    currentParticipant => {
        const participantInfos: TEnoField[][] = []
        participantInfos.push([{_type: NAME(t), _value: currentParticipant._billingDetail._name || "", _editable: true}])
        participantInfos.push([{_type: ADDRESS(t), _value: currentParticipant._billingDetail._address|| "", _editable: true}])
        participantInfos.push([{_type: CITY(t), _value: currentParticipant._billingDetail._city|| "", _editable: true}])
        participantInfos.push([{_type: POSTCODE(t), _value: currentParticipant._billingDetail._postCode|| "", _editable: true}])

        participantInfos.push([{_type: PHONENUMBER(t), _value: currentParticipant._billingDetail._phoneNumber|| "", _editable: true}])
        participantInfos.push([{_type: EMAIL(t), _value: currentParticipant._billingDetail._email|| "", _editable: true}])
        return participantInfos || []
    }
)
const selectParticipantsEnedisDetails = createSelector(
    selectUserParticipants,
    participants => participants.map((participant:EParticipant) => participant._enedisDetail)
)
const selectParticipantsDsoDetails = createSelector(
    selectUserParticipants,
    participants => participants.map((participant:EParticipant) => participant._dsoDetails)
)
const selectParticipantDsoIdentifier = createSelector(
    selectUserParticipants,
    participants => participants.map((participant:EParticipant) => participant._dsoDetails._dsoIdentifier)
)
const selectParticipantPrm = createSelector(
    selectUserParticipants,
    participants => participants.map((participant:EParticipant) => participant._enedisDetail._prm)
)


export {
    selectBillingInfos,
    selectCurrentParticipantBillingDetail,
    selectParticipantInfos,
    selectUserParticipants,
    selectCurrentParticipant,
    selectCurrentParticipantId,
    selectCurrentParticipantDsoDetails,
    selectCurrentParticipantEnedisDetail,
    selectCurrentParticipantOperationId,
    selectCurrentParticipantUserId,
    selectParticipantDsoIdentifier,
    selectParticipantPrm,
    selectParticipantsDsoDetails,
    selectParticipantsEnedisDetails
}
